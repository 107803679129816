import {put, call} from 'redux-saga/effects';

import * as types from '../actions'
import {login} from "../actions/authenticationActions";
import {REASON_CODES} from "../constants/reasonCodes";
import {LOGIN_TOKEN_NAME} from "../constants/appConstants";
import {setAuthorizationToken} from "../services/httpService";


export function* loginSaga(payload) {
  try {
    const response = yield call(login, payload.user);
    const data = response.data;
    if (data.token) {


      let jwt = require('jsonwebtoken');


      localStorage.setItem(LOGIN_TOKEN_NAME, data.token);
      localStorage.setItem("permissions", JSON.stringify(data.permissions));
      localStorage.setItem("F_CLIENT_ID", data.F_CLIENT_ID);
      localStorage.setItem("Company", JSON.stringify(data.Company.name));
      localStorage.setItem("is_accounting", JSON.stringify(data.Company.is_accounting));
      localStorage.setItem("productpermission", JSON.stringify(data.Company.is_product));
      localStorage.setItem("banglaPermission", JSON.stringify(data.Company.is_show_bangla_language));
      localStorage.setItem("cngpermission", JSON.stringify(data.Company.is_cng));
      localStorage.setItem("loanpermission", JSON.stringify(data.Company.is_loan));
      localStorage.setItem("is_sale_discount", JSON.stringify(data.Company.is_sale_discount));
      localStorage.setItem("corporateSalepermission", JSON.stringify(data.Company.is_corporate_sale));
      localStorage.setItem("printPreviewPermission", JSON.stringify(data.Company.is_print_preview));
      localStorage.setItem("specialCorporatepermission", JSON.stringify(data.Company.is_special_corporate));
      localStorage.setItem("customerGrouppermission", JSON.stringify(data.Company.is_customer_group));
      localStorage.setItem("allItems", JSON.stringify(data.menubar));
      localStorage.setItem("is_fuel_cash_drawer", JSON.stringify(data.Company.is_fuel_cash_drawer));
      localStorage.setItem("is_show_pos_operator_name", JSON.stringify(data.Company.is_show_pos_operator_name));
      localStorage.setItem("modalFilled", JSON.stringify(data.is_admin));
      localStorage.setItem("is_admin", JSON.stringify(data.is_admin));

      let date = new Date();
      localStorage.setItem("default_date", date);


      const product_permission = localStorage.getItem("productpermission", JSON.stringify(data.Company.is_product));
      const cng_permission = localStorage.getItem("cngpermission", JSON.stringify(data.Company.is_cng));
      const corporate_sale_permission = localStorage.getItem("corporateSalepermission", JSON.stringify(data.Company.is_corporate_sale));
      const loan_permission = localStorage.getItem("loanpermission", JSON.stringify(data.Company.is_loan));
      if(product_permission == 1){
        const arr = JSON.parse(localStorage.getItem('permissions')) || [];
        // arr[arr.length] = "view product_permission";
        localStorage.setItem("permissions", JSON.stringify(arr));
      }
      if(cng_permission == 1){
        const arr = JSON.parse(localStorage.getItem('permissions')) || [];
        // arr[arr.length] = "view cng_permission";
        localStorage.setItem("permissions", JSON.stringify(arr));
      }
      if(loan_permission == 1){
        const arr = JSON.parse(localStorage.getItem('permissions')) || [];
        // arr[arr.length] = "view loan_permission";
        localStorage.setItem("permissions", JSON.stringify(arr));
      }
      if(corporate_sale_permission == 1){
        const arr = JSON.parse(localStorage.getItem('permissions')) || [];
        // arr[arr.length] = "view corporate_sale_permission";
        localStorage.setItem("permissions", JSON.stringify(arr));
      }
      // localStorage.setItem(JSON.strigify('permissions', data));
      setAuthorizationToken(data.token);

      yield put({type: types.SET_CURRENT_USER, user: jwt.decode(data.token)});
    } else {
      if (data.rc && data.rc === REASON_CODES.VALIDATION_FAILED) {
        yield put({type: types.LOGIN_USER_VALIDATION_FAILED, data: data});
      }
    }
  } catch (error) {
    yield put({type: types.LOGIN_USER_ERROR, error})
  }
}
